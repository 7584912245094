
.json-form .ant-form-item-label {
    padding-bottom: 5px;
}

.json-form .ant-form-item-label label {
    height: 30px !important;
}

/*.json-form .ant-form-item {*/
/*    margin: 0;*/
/*}*/