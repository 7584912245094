body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --common-select-max-with: 250px;
  --common-intecation-elements-height: 50px;
  --common-checkbox-size: 18px;
  --dangerous-color: rgb(255, 77, 79);
}

.a-common-select {
  width: 100%;
  height: var(--common-intecation-elements-height);
  max-width: var(--common-select-max-with);
}

.a-common-button {
  height: var(--common-intecation-elements-height);
}

.ant-checkbox-input {
  width: var(--common-checkbox-size);
  height: var(--common-checkbox-size)
}

.ant-checkbox-inner {
  width: var(--common-checkbox-size) !important;
  height: var(--common-checkbox-size) !important;
  border-color: rgb(80, 80, 80) !important;
  border-width: 2px !important;
  border-radius: 2px !important;
}

.a-common-form label {
  font-size: 16px !important;
}
.a-common-form .a-common-form-label {
  font-size: 16px !important;
}

.a-bordered-side-menu-item {
  background-image: linear-gradient(to right,
    transparent 25%,
    rgba(255, 255, 255, 0.65) 25%,
    rgba(255, 255, 255, 0.65) 75%,
    transparent 75%);
  background-repeat: no-repeat;
  background-position: top;
  background-size: 180% 1px;
  padding-top: 10px;
}